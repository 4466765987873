import React, { useEffect, useState } from "react";
import "./App.css";
import { config } from "./Constants";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import CustomizedAccordions from "./components/summarizer/summarizer";
import SendIcon from "@mui/icons-material/Send";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

type UrlInputProps = {
  url: string;
  setUrl: (url: string) => void;
  onSubmit: (url: string) => void;
  isSummaryLoading: boolean;
  setIsSummaryLoading: (isSummaryLoading: boolean) => void;
};

const UrlInput: React.FC<UrlInputProps> = ({
  onSubmit,
  url,
  setUrl,
  isSummaryLoading,
  setIsSummaryLoading,
}) => {
  const [textBox, setTextBox] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextBox(event.target.value);
  };

  const isValidUrl = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsSummaryLoading(true);
    onSubmit(textBox);
    setUrl(textBox);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      sx={{ padding: "0 16px" }}
      style={{ display: "flex", alignItems: "stretch" }}
    >
      <TextField
        label="Enter pdf url"
        variant="outlined"
        value={textBox}
        onChange={handleChange}
        required
        className="url-input-text"
      />
      <LoadingButton
        loading={isSummaryLoading}
        loadingPosition="end"
        type="submit"
        variant="contained"
        color="primary"
        endIcon={<SendIcon />}
        disabled={textBox === "" || textBox === url || !isValidUrl(textBox)}
        className="url-submit"
      >
        Submit
      </LoadingButton>
    </Box>
  );
};

function HelloComponent() {
  const urlBase = config.url;
  const fetchURL = `${urlBase}/hello`;
  const [helloData, setHelloData] = useState<string>(
    `Connecting to backend...`
  );

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(fetchURL);
      const data = await response.json();
      if (data.data) {
        setHelloData("");
      } else {
        setHelloData(`Error connecting to backend at ${fetchURL}`);
      }
    }
    fetchData();
  }, [fetchURL, urlBase]);

  return <div>{helloData && <pre>{helloData}</pre>}</div>;
}

function App() {
  const [url, setUrl] = useState<string>("");
  const [summaryData, setSummaryData] = useState<Array<Array<string>>>([]);
  const [isSummaryLoading, setIsSummaryLoading] = useState<boolean>(false);

  React.useEffect(() => {
    const fetchSummaryData = async () => {
      if (url !== "") {
        setSummaryData([]);
        const urlBase = config.url;
        const encodedUrl = encodeURIComponent(url);
        const fetchURL = `${urlBase}/summarize?url=${encodedUrl}`;
        const response = await fetch(fetchURL);
        if (!response.ok) {
          console.log("Minh: Error response", response, fetchURL);
          setSummaryData([
            ["Error invalid URL", "try https://www.orimi.com/pdf-test.pdf"],
          ]);
          setIsSummaryLoading(false);
          return null;
        }
        const data = await response.json();
        if (data) {
          setSummaryData(data.data);
        }
      } else {
        setSummaryData([]);
      }
      setIsSummaryLoading(false);
    };
    fetchSummaryData();
  }, [url]);

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <header className="App-header">
          {HelloComponent()}
          <div className="summarizer-container">
            <h1 style={{ fontSize: "3rem" }}>PDF Summarizer</h1>
            <h2 style={{ fontSize: "1.5rem" }}>
              Submit a url to a pdf you want to summarize
            </h2>
            {UrlInput({
              onSubmit: (url: string) => console.log("Minh: url", url),
              url,
              setUrl,
              isSummaryLoading: isSummaryLoading,
              setIsSummaryLoading: setIsSummaryLoading,
            })}
            {CustomizedAccordions({ data: summaryData })}
          </div>
          {process.env.NODE_ENV !== "production" && (
            <p style={{ fontSize: "1rem" }}>
              Running in {process.env.NODE_ENV}.
            </p>
          )}
        </header>
      </div>
    </ThemeProvider>
  );
}

export default App;
